// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Complemento de pago</h2>
                <p>
                    El complemento de pago es solo una factura normal con algunos campos menos y los campos de pago adicionales. El cambio mas notorio entre una factura normal y un complemento de pago es <b>El total de la factura siempre es $0</b>. <br/>
                    Sin embargo, nosotros solo requerimos los conceptos de pago,los demás campos se generan automáticamente.
                </p>
                <p>
                    <b>Si estas cambiando CFDI 3.3 a 4.0</b> solamente hay dos campos de receptor que debes agregar marcados en azul a continuación. 
                    El complemento de pago 2.0 (para CFDO 4.0) ya no requiere datos bancarios y es mas sencillo de emitir.
                </p>
                <p>
                    Los complementos de pago <b>Siempre se deben emitir contra una factura que tenga metodo de pago PPD y forma de pago 99</b>. La ruta para emitir un complemento de pago es diferente de las factuas normales.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi<b>/payment</b>/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>lugarExpedicion:</b>Código postal del emisor de la factura, este campo sobrescribe el valor del perfil del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>serie:</b> Serie de la factura de complemento de pago.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>folio:</b> Folio de la factura de complemento de pago, igual que las facturas normales.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>receptor:</b> Un solo objeto que representa al receptor con las siguientes características.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.nombre:</b> Razón social del receptor de la factura.
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.rfc:</b> RFC del receptor de la factura (Ejemplo muestra RFC de venta a mostrador).
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.usoCFDI:</b> Clave del uso de lafactura. <em>Consultar catálogo SAT</em>
                                </p>
                            </li>
                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.regimen: (CFDI 4.0)</b> Clave del Régimen del receptor. <em><Link to="api/catalogo/catalogo-regimenfiscal">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.zip: (CFDI 4.0)</b> Código postal del Receptor, en caso de RFC genérico se usa el Lugar de Expedición del Emisor <em>Debe ser un String de 5 carácteres numéricos</em>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <b>pagos[ ]:</b> Un array de complementos de pago.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">pagos</span>.fecha:</b> Fecha cuando se realizó el pago, tal como dice el comprobante de pago o voucher si fue transferencia o tarjeta.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">pagos</span>.formaDePago:</b> Forma de pago aplicable, <code>'01' | '02' | '03', etc</code>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">pagos</span>.monto:</b> El monto total del pago, ya que se puede generar un solo pago que cubra una o más facturas, aqui se declara el total pagado.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>pagos.facturaRelacionada [ ]:</b> Un array de Facturas relacionadas, <span className="accent">cuando hay mas de una factura relacionada (se paga dos facturas o mas) se debe especificar el monto.</span> <br />
                                    <span className="small quote">Cuando solamente hay una factura relacionada (Array de un solo elemento) no es necesario especificar el monto.</span>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">pagos.facturaRelacionada</span>.uuid:</b> El UUID que se está relacionando en el complemento de pago.
                                    </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">pagos.facturaRelacionada</span>.importePagado: (Solo al usar mas de 1 factura relacionada)</b> De el monto total, cuanto es de esta factura.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">pagos.facturaRelacionada</span>.numParcialidad: (opcional)</b> ¿Que parcialidad es? este es un campo completamente opcional, nosotros aplicamos el numero de parcialidad automático considerando los demas complementos de pago que se han generado para aplicar una secuencia. <br />
                                            <span className="quote accent">Hay pocas razones para declarar numParcialidad, pero si hubo un problema en la secuencia de la facturación, podría mandarse para reemplazar la secuencia que tenemos nosotros.</span>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, en el caso de complementos de pago, contiene información importante para obtener el estado de pago de las facturas
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">json.["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"]["pago10:DoctoRelacionado"]["@"]</span>.NumParcialidad:</b> El numero de parcialidad secuencial, automáticamente va incrementando según se entreguen pagos.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">json.["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"]["pago10:DoctoRelacionado"]["@"]</span>.ImpSaldoAnt:</b> El saldo anterior, saldo pendiente antes de realizar el pago.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">json.["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"]["pago10:DoctoRelacionado"]["@"]</span>.ImpPagado:</b> El monto pagado para este documento, como se estableció en el request.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">json.["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"]["pago10:DoctoRelacionado"]["@"]</span>.ImpSaldoInsoluto:</b> Saldo pendiente por loquidar después del pago, la factura no se habrá saldado hasta que llegue a $0.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <p>
                    Un concepto importante, <b>Los pagos no manejan impuestos, por lo que se considera que el pago incluye impuestos.</b>
                </p>
                <SectionNavigate next={`/api/referencia/cfdi-complemento-notarios`} previous={`/api/referencia/cfdi-facturar-pago`}/>
            </div>
            <div className="side">
                <CodeBlock title="CFDI Emitir" requests={cfdiScripts.pago.requests} response={cfdiScripts.pago.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
